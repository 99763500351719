import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  position: absolute !important;
  left: 0;
  top: 0;
  z-index: 1;
`

const StyledStaticImage = styled.img`
  width: 100%;
  height: 100%;
  position: absolute !important;
  left: 0;
  top: 0;
  z-index: 1;
`

interface ImageProps {
  className?: string
  image: any
  alt?: string
  staticSource?: boolean
  loading?: 'lazy' | 'eager'
}

const BackgroundImage: React.FC<ImageProps> = ({
  className = '',
  image,
  staticSource = false,
  alt = '',
  loading = 'lazy',
}) => {
  if (staticSource) {
    return (
      <StyledStaticImage
        loading={loading}
        className={className}
        src={image}
        alt={alt}
      />
    )
  }

  if (!image?.localFile?.childImageSharp?.gatsbyImageData) {
    return null
  }
  return (
    <StyledGatsbyImage
      loading={loading}
      className={className}
      image={image?.localFile?.childImageSharp?.gatsbyImageData}
      alt={alt}
    />
  )
}

export default BackgroundImage
