import React from 'react'
import CustomLink from 'components/shared/CustomLink'

interface ButtonShellProps {
  isAnchor?: boolean
  to: string
  children: any
  className?: string
}

const ButtonShell: React.FC<ButtonShellProps> = ({
  isAnchor = false,
  to,
  children,
  className = '',
}) =>
  !isAnchor ? (
    <CustomLink to={to} className={className}>
      {children}
    </CustomLink>
  ) : (
    <a href={to} className={className}>
      {children}
    </a>
  )

export default ButtonShell
