import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import CustomLink from 'components/shared/CustomLink'

// Images
import ChevronDown from 'img/chevron-down.inline.svg'

// Third Party
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

interface MobileMenuProps {
  fields: any
  setMobileOpen: Function
  variant: 'pfz' | 'ecd'
}

const CloseIcon = styled.div`
  height: 30px;
  width: 30px;
  position: absolute;
  top: 50px;
  right: 30px;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 6px;
    background-color: ${({ theme }) => theme.colors.light};
    width: 40px;
    border-radius: 4px;
    transform: rotate(45deg);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 6px;
    background-color: ${({ theme }) => theme.colors.light};
    width: 40px;
    border-radius: 4px;
    transform: rotate(315deg);
  }
`

const StyledMobileMenu = styled.div<{ variant: 'pfz' | 'ecd' }>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  height: 100vh;
  width: 100vw;

  ${({ variant, theme }) =>
    variant === 'pfz'
      ? css`
          background-color: ${theme.colors.secondary};
        `
      : css`
          background-color: ${theme.colors.primary};
        `}
`

const MenuWrapper = styled.div`
  width: 90%;

  & a {
    &[aria-current] {
      color: ${({ theme }) => theme.colors.light} !important;
      text-decoration: underline;
    }
  }
`

const SubMenu = styled(motion.div)`
  position: relative;
  background: white;
  z-index: 5;
  width: 100%;
  left: 0%;
  border-radius: 24px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);

  & a {
    font-size: 20px !important;

    &[aria-current] {
      color: ${({ theme }) => theme.colors.tertiary} !important;
      text-decoration: underline;
    }
  }
`

const Count = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.tertiary};
  top: 12px;
  left: 185px;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  color: ${({ theme }) => theme.colors.light};
  font-size: 20px;
  line-height: 12px;

  & > div {
    position: absolute;
    top: 10px;
    left: 10px;
  }
`

const IconContainer = styled.div`
  & svg {
    width: 36px;
    height: 36px;
  }
`

const MobileMenu: React.FC<MobileMenuProps> = ({
  fields,
  variant,
  setMobileOpen,
}) => {
  const [currentIndex, setCurrentIndex] = React.useState<number | null>(null)

  const query =
    useStaticQuery<GatsbyTypes.getMobileTotalVacanciesQuery>(graphql`
      query getMobileTotalVacancies {
        allWpVacature {
          totalCount
        }
      }
    `)

  const menuAnimations = {
    enter: {
      height: 'fit-content',
      opacity: 1,
      rotateX: 0,
      pointerEvents: 'all',
      overflow: 'hidden',
      transition: {
        duration: 0.2,
      },
      display: 'flex',
    },
    exit: {
      height: 0,
      opacity: 0,
      pointerEvents: 'none',
      rotateX: -15,
      overflow: 'hidden',
      transition: {
        duration: 0.2,
      },
      transitionEnd: {
        display: 'none',
      },
    },
  }

  return (
    <StyledMobileMenu variant={variant}>
      <CloseIcon onClick={() => setMobileOpen(false)} />
      <div className="d-flex flex-column h-100 align-items-center justify-content-center">
        <MenuWrapper>
          {fields.menuItems.map((menuItem: any, index: number) => {
            if (!menuItem.submenu) {
              return (
                <CustomLink
                  key={menuItem.link.title}
                  className="text-light position-relative d-block font-size-4xl font-family-secondary ml-5"
                  to={menuItem.link.url}
                >
                  {menuItem.link.title}
                  {menuItem.link.title === 'Werken bij' && (
                    <Count>
                      <div>{query.allWpVacature.totalCount}</div>
                    </Count>
                  )}
                </CustomLink>
              )
            }

            return (
              <div className="position-relative">
                <motion.div
                // onMouseEnter={() => setCurrentIndex(index)}
                // onMouseLeave={() => setCurrentIndex(null)}
                >
                  <div className="d-flex justify-content-between">
                    <CustomLink
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      to={menuItem.link.url}
                      className="text-light d-block font-size-4xl font-family-secondary ml-5"
                    >
                      {menuItem.link.title}
                    </CustomLink>
                    <IconContainer
                      onClick={() =>
                        setCurrentIndex(index === currentIndex ? -1 : index)
                      }
                      className="d-flex align-items-center justify-content-end"
                    >
                      <ChevronDown />
                    </IconContainer>
                  </div>

                  <SubMenu
                    initial="exit"
                    animate={currentIndex === index ? 'enter' : 'exit'}
                    variants={menuAnimations}
                  >
                    <div className="d-flex flex-column py-3">
                      {menuItem.submenu.map((subMenuItem: any, key: number) => (
                        <CustomLink
                          to={subMenuItem.link.url}
                          className="px-5 py-2 font-size-3xl font-family-secondary"
                          key={key}
                        >
                          {subMenuItem.link.title}
                        </CustomLink>
                      ))}
                    </div>
                  </SubMenu>
                </motion.div>
              </div>
            )
          })}
        </MenuWrapper>
      </div>
    </StyledMobileMenu>
  )
}

export default MobileMenu
