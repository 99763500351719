import React from 'react'
import styled, { css } from 'styled-components'
import ButtonShell from 'components/elements/ButtonShell'

import ArrowRight from 'img/arrow-right.inline.svg'

const StyledButton = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.dark};
  color: ${({ theme }) => theme.colors.primary};
  font-family: ${({ theme }) => theme.font.family.secondary};
  border-radius: 1.875em;
  line-height: 1;
  padding: 0;
  margin: 0;
  font-size: ${({ theme }) => theme.font.size.xl};

  ${(props) =>
    props.type !== undefined &&
    css`
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0.5em 1em 0.5em 1em;
    `};

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.5em 1em 0.5em 1em;
  }

  span {
    margin-bottom: -2px;
  }

  svg.arrow {
    width: 12px;
    height: 10px;
    margin-left: 0.75em;
    width: 1rem;
  }

  ${(props) =>
    Object.entries(props.theme.colors).map(
      ([key, value]) =>
        `
          &.${key} {
            color: ${value};
            border-color: ${value};

            &:not(.filled) a {
              color: ${value};
            }
          }

          &.${key}.filled-white {
            background-color: ${props.theme.colors.light};
            border-color: ${props.theme.colors.light};

            &.colored a {
              color: ${value};
            }

            a {
              color: ${props.theme.colors.primary};
            }

            svg {
              color: ${value};
            }
          }

          &.${key}.filled {
            background-color: ${value};
            border-color: ${value};

            a {
              color: ${props.theme.colors.light} !important;
            }

            svg {
              color: ${props.theme.colors.light};
            }
          }
        `
    )}
`

interface ButtonDefaultProps {
  isAnchor?: boolean
  to?: string
  children: any
  className?: string
  arrow?: boolean
  type?: 'submit' | 'button'
  onClick?: () => void
}

const Button: React.FC<ButtonDefaultProps> = ({
  isAnchor = false,
  to = '',
  children,
  className = '',
  arrow = false,
  type = undefined,
  onClick = () => null,
}) => (
  <StyledButton onClick={onClick} type={type} className={className}>
    {to === '' ? (
      <>
        <span>{children}</span>
        {arrow && <ArrowRight className="arrow" />}
      </>
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        <span>{children}</span>

        {arrow && <ArrowRight className="arrow" />}
      </ButtonShell>
    )}
  </StyledButton>
)

export default Button
