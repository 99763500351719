import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

import Button from 'components/elements/Button'
import CustomLink from 'components/shared/CustomLink'

// @ts-ignore
import logo from 'img/logo.svg'
// import Logo from 'img/logo.inline.svg'
import MobileMenu from 'components/layout/headers/MobileMenu'

const SubMenu = styled(motion.div)`
  position: absolute;
  background: white;
  z-index: 5;
  min-width: 200px;
  border-radius: 0 0 24px 24px;
`

interface Props {
  fields?: any
  variant: 'pfz' | 'ecd'
  id?: number
}

const MenuStripe = styled.div<{ variant: 'pfz' | 'ecd' }>`
  position: relative;
  right: 15px;
  height: 6px;
  width: 40px;
  margin: 6px 0;
  ${({ variant, theme }) =>
    variant === 'pfz'
      ? css`
          background-color: ${theme.colors.secondary};
        `
      : css`
          background-color: ${theme.colors.primary};
        `}
  border-radius: 4px;
`

const Count = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.secondary};
  top: -10px;
  right: -25px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  color: ${({ theme }) => theme.colors.light};

  & > div {
    position: absolute;
    top: 0px;
    left: 6px;
  }
`

const StripeContainer = styled.div`
  position: relative;
  z-index: 20;
`

const OtherLogo = styled.img``

function HeaderDefault({ id, fields, variant }: Props) {
  const [currentIndex, setCurrentIndex] = React.useState<number | null>(null)
  const [mobileOpen, setMobileOpen] = React.useState<boolean>(false)

  const query = useStaticQuery<GatsbyTypes.getTotalVacanciesQuery>(graphql`
    query getTotalVacancies {
      allWpVacature {
        totalCount
      }
    }
  `)

  const menuAnimations = {
    enter: {
      opacity: 1,
      rotateX: 0,
      pointerEvents: 'all',
      overflow: 'hidden',
      transition: {
        duration: 0.2,
      },
      display: 'flex',
    },
    exit: {
      opacity: 0,
      pointerEvents: 'none',
      rotateX: -15,
      overflow: 'hidden',
      transition: {
        duration: 0.2,
      },
      transitionEnd: {
        display: 'none',
      },
    },
  }

  return (
    <header className="py-3">
      <div className="container">
        <div className="row">
          <div className="col-3 d-flex align-items-end">
            <Link to="/">
              <OtherLogo src={logo} />
              {/* <Logo className="mr-lg-5" /> */}
            </Link>
          </div>

          {/* Desktop Header */}

          <div className="col-lg-9 d-lg-flex d-none flex-column align-items-end justify-content-between">
            {![3182, 749].includes(id || 0) ? (
              <div className="w-100 d-flex justify-content-end">
                {fields.buttons.map((button: any) => (
                  <Button
                    isAnchor
                    key={button.link.title}
                    to={button.link.url}
                    className={
                      variant === 'ecd'
                        ? `primary font-size-base ml-4`
                        : `secondary filled font-size-base ml-4`
                    }
                    arrow
                  >
                    {button.link.title}
                  </Button>
                ))}
              </div>
            ) : (
              <div />
            )}

            <div className="w-100 d-flex justify-content-end">
              {fields.menuItems.map((menuItem: any, index: number) => {
                if (!menuItem.submenu) {
                  return (
                    <CustomLink
                      key={menuItem.link.title}
                      className="text-tertiary position-relative d-block font-family-secondary ml-4 ml-xl-5"
                      to={menuItem.link.url}
                    >
                      {menuItem.link.title}
                      {menuItem.link.title === 'Werken bij' && (
                        <Count>
                          <div>{query.allWpVacature.totalCount}</div>
                        </Count>
                      )}
                    </CustomLink>
                  )
                }

                return (
                  <div className="position-relative" key={menuItem.link.title}>
                    <motion.div
                      onMouseEnter={() => setCurrentIndex(index)}
                      onMouseLeave={() => setCurrentIndex(null)}
                    >
                      <CustomLink
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        to={menuItem.link.url}
                        className="text-tertiary d-block font-family-secondary ml-4 ml-xl-5"
                      >
                        {menuItem.link.title}
                      </CustomLink>
                      <SubMenu
                        initial="exit"
                        animate={currentIndex === index ? 'enter' : 'exit'}
                        variants={menuAnimations}
                        className="d-flex flex-column py-2"
                      >
                        {menuItem.submenu.map(
                          (subMenuItem: any, key: number) => (
                            <CustomLink
                              to={subMenuItem.link.url}
                              className="px-5 py-2 font-family-secondary"
                              key={key}
                            >
                              {subMenuItem.link.title}
                            </CustomLink>
                          )
                        )}
                      </SubMenu>
                    </motion.div>
                  </div>
                )
              })}
            </div>
          </div>

          {/* Mobile Header */}
          <div className="col-8 d-flex d-lg-none flex-column align-items-end justify-content-between">
            <div className="w-100 d-flex justify-content-end">
              {variant === 'pfz' && (
                <>
                  {fields.buttons.map((button: any) => (
                    <Button
                      isAnchor
                      key={button.link.title}
                      to={button.link.url}
                      className="secondary filled font-size-base ml-lg-4 ml-1"
                      arrow
                    >
                      {button.link.title}
                    </Button>
                  ))}
                </>
              )}
            </div>
            <StripeContainer
              role="button"
              tabIndex={-1}
              onKeyDown={() => setMobileOpen(true)}
              onClick={() => setMobileOpen(true)}
              className="pt-2"
            >
              <MenuStripe variant={variant} />
              <MenuStripe variant={variant} />
              <MenuStripe variant={variant} />
            </StripeContainer>
            {mobileOpen && (
              <MobileMenu
                setMobileOpen={setMobileOpen}
                variant={variant}
                fields={fields}
              />
            )}
          </div>
        </div>
      </div>
    </header>
  )
}

export default HeaderDefault
