const mainTheme = {
  colors: {
    primary: '#00A9EA',
    secondary: '#E70087',
    tertiary: '#000080',
    light: '#fff',
    dark: '#353535',
  },

  font: {
    family: {
      main: 'Open Sans, Verdana',
      secondary: 'Priborg Sans, Open Sans',
      tertiary: 'europa, Open Sans',
    },

    size: {
      xs: '12px',
      sm: '14px',
      base: '16px',
      lg: '18px',
      xl: '20px',
      '2xl': '22px',
      '3xl': '30px',
      '4xl': '40px',
      '5xl': '50px',
      '6xl': '75px',
    },

    weight: {
      thin: 100,
      extralight: 200,
      light: 300,
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
      black: 900
    },

    height: {
      'none': 1,
    }
  },
}

export default mainTheme
