import React from 'react'
import styled, { ThemeProvider, createGlobalStyle } from 'styled-components'
import mainTheme from 'styles/Theme'

import checkmarkIcon from 'img/checkmark.png'

const Container = styled.div`
  max-width: 1920px;
  margin-left: auto;
  margin-right: auto;
`

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
  }

  body {
    font-family: ${(props) => props.theme.font.family.main};
    font-size: ${(props) => props.theme.font.size.m};
    color: ${(props) => props.theme.colors.dark};
  }

  ${(props) =>
    Object.keys(props.theme.colors).map(
      (key) =>
        `
        .bg-${key} { background-color: ${props.theme.colors[key]} !important; }
        .text-${key} { color: ${props.theme.colors[key]} !important; }
        `
    )}

  ${(props) =>
    Object.keys(props.theme.font.family).map(
      (key) =>
        `.font-family-${key} { font-family: ${props.theme.font.family[key]}; }`
    )}

  ${(props) =>
    Object.keys(props.theme.font.size).map(
      (key) =>
        `.font-size-${key} { font-size: ${props.theme.font.size[key]} !important; }`
    )}

  ${(props) =>
    Object.keys(props.theme.font.weight).map(
      (key) =>
        `.font-weight-${key} { font-weight: ${props.theme.font.weight[key]}; }`
    )}

  ${(props) =>
    Object.keys(props.theme.font.height).map(
      (key) =>
        `.line-height-${key} { line-height: ${props.theme.font.height[key]}; }`
    )}

  a {
    color: ${({ theme }) => theme.colors.tertiary};;

    &:hover {
      color: ${({ theme }) => theme.colors.primary};;
    }
  }

  a[aria-current] {
    color: ${(props) =>
      props.type === 'ecd'
        ? props.theme.colors.primary
        : props.theme.colors.secondary} !important;
  }

  .button {
    border: 1px solid ${({ theme }) => theme.colors.dark};
    color: ${({ theme }) => theme.colors.dark};
    font-family: ${({ theme }) => theme.font.family.secondary};
    border-radius: 30px;
    line-height: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  a.link.arrow {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-size: ${({ theme }) => theme.font.size.lg};
    display: inline-flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.primary};;

    svg {
      width: 12px;
      height: 10px;
      margin-left: 0.5em;
      width: 1rem;
    }
  }

  /* For WordPress colors */
  span[style] > a.link.arrow {
    color: inherit;
  }

  h1,h2,h3,h4,h5,h6 {
    font-family: ${({ theme }) => theme.font.family.secondary};
    margin-bottom: 15px;
    font-weight: 500;
    line-height: 1;
  }

  ul {
    margin-bottom: 1.25rem;
  }

  p {
    line-height: 1.875;
  }

  h1 {
    font-size: ${({ theme }) => theme.font.size['6xl']};

    @media screen and (max-width: 576px) {
      font-size: ${({ theme }) => theme.font.size['5xl']} !important;
    }
  }

  h2 {
    font-size: ${({ theme }) => theme.font.size['5xl']};

    @media screen and (max-width: 576px) {
      font-size: ${({ theme }) => theme.font.size['4xl']};
    }
  }

  h3 {
    font-size: ${({ theme }) => theme.font.size['4xl']};
  }

  h4 {
    font-size: ${({ theme }) => theme.font.size['3xl']};
  }

  h5 {
    font-size: ${({ theme }) => theme.font.size['2xl']};
  }

  h6 {
    font-size: ${({ theme }) => theme.font.size.xl};
  }

  .icon-medium {
    flex: 0 0 .75em;
    width: .75em;
    height: .75em;
  }

  .icon-18 {
    flex: 0 0 18px;
    width: 18px;
    height: 18px;
  }

  .icon-24 {
    flex: 0 0 24px;
    width: 24px;
    height: 24px;
  }

  .hide-last-element {
    > *:last-child {
      display: none;
    }
  }

  ul.checkmarks {
    list-style: none;
    padding-left: 0px;

    li {
      position: relative;
      padding-left: 20px;
      align-items: flex-start;
    }

    li::before {
      content: "";
      width: 18px;
      height: 18px;
      display: flex;
      position: absolute;
      left: -2px;
      top: 3px;
      margin-right: 5px;
      background-image: url(${checkmarkIcon});
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }

    li {
      margin-bottom: 5px;
    }
  }

  .card {
    && {
      background: rgb(255, 255, 255);
      border-radius: 50px;
      padding: 50px 70px;
      color: ${({ theme }) => theme.colors.dark};
      border: none !important;
      @media (max-width: 991px) {
        padding: 30px 30px;
      }

      @media (max-width: 576px) {
        border-radius: 30px;
      }
    }

    &.shadow {
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2) !important;
    }

    &.shadow-sm {
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15) !important;
    }

    &.blur {
      backdrop-filter: blur(4px);
    }

    &.transparent {
      background: rgba(255, 255, 255, 0.65);
    }

    &.transparent-md {
      background: rgba(255, 255, 255, 0.45);
    }

    &.card-small {
      border-radius: 35px;
      padding: 20px 20px;
    }
  }

  .rounded-35 {
    border-radius: 35px;
  }

  .children-mb-0 {
    *:last-child {
      margin-bottom: 0 !important;
    }
  }

  .h-mb-0 {
    h1,h2,h3,h4,h5,h6 {
      margin-bottom: 0;
    }
  }

  .z-index-2 {
    z-index: 2;
  }

  .z-index-3 {
    z-index: 3;
  }
`

const Theme = ({ children, type }) => (
  <ThemeProvider theme={mainTheme}>
    <Container>
      <GlobalStyle type={type} />
      {children}
    </Container>
  </ThemeProvider>
)

export default Theme
