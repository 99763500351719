import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import HeaderHome from 'components/layout/headers/Home'
import HeaderDefault from 'components/layout/headers/Default'

interface Props {
  id?: number
  type: 'home' | 'pfz' | 'ecd'
}

function Header({ id, type }: Props) {
  const { fields } = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      fields: wpComponent(databaseId: { eq: 97 }) {
        id
        databaseId
        header {
          menuHome {
            slogan
            clientButton {
              title
              url
            }
            ecdButton {
              title
              url
            }
            phoneButton {
              title
              url
            }
            vacanciesButton {
              title
              url
            }
          }

          menuEcd {
            buttons {
              link {
                title
                url
              }
            }
            menuItems {
              link {
                title
                url
              }
              submenu {
                link {
                  title
                  url
                }
              }
            }
          }

          menuPfz {
            buttons {
              link {
                title
                url
              }
            }
            menuItems {
              link {
                title
                url
              }
              submenu {
                link {
                  title
                  url
                }
              }
            }
          }
        }
      }
    }
  `)

  if (!fields || !fields.header) return null

  if (type === 'home') {
    return <HeaderHome fields={fields.header.menuHome} />
  }

  if (type === 'ecd') {
    return (
      <HeaderDefault id={id} variant="ecd" fields={fields.header.menuEcd} />
    )
  }

  if (type === 'pfz') {
    return (
      <HeaderDefault id={id} variant="pfz" fields={fields.header.menuPfz} />
    )
  }

  return null
}

export default Header
