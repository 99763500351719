import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled from 'styled-components'
import parse from 'html-react-parser'
import ParseContent from 'components/shared/ParseContent'

import ArrowRightIcon from 'img/arrow-right.inline.svg'

import LocationIcon from 'img/location.inline.svg'
import PhoneIcon from 'img/phone.inline.svg'
import MailIcon from 'img/mail.inline.svg'
import LinkedInIcon from 'img/linkedin.inline.svg'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.colors.tertiary};
  color: ${(props) => props.theme.colors.light};
`

const HrSecondary = styled.div`
  width: 1px;
  height: 20px;
  background: ${({ theme }) => theme.colors.secondary};
`

const ModuleItems = styled.div`
  max-height: 320px;

  @media screen and (max-width: 575px) {
    max-height: unset;
  }
`

interface Props {
  type: 'home' | 'pfz' | 'ecd'
}

const LocationContent = styled(ParseContent)`
  & a {
    color: ${({ theme }) => theme.colors.light};

    &:hover {
      text-decoration: underline;
    }
  }
`

const Footer = ({ type }: Props) => {
  // eslint-disable-next-line
  const { fields, modules } =
    useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
      query footerQuery {
        fields: wpComponent(databaseId: { eq: 98 }) {
          footer {
            general {
              title
              address
              email
              phone
              linkedin
            }

            ecd: footerEcd {
              menu1 {
                title
                links {
                  link {
                    title
                    url
                  }
                }
              }

              menu2 {
                title
              }
            }

            pluriform: footerPluriform {
              menu1 {
                title
                links {
                  link {
                    title
                    url
                  }
                }
              }

              menu2 {
                title
                links {
                  link {
                    title
                    url
                  }
                }
              }
            }

            bottom: footerBottom {
              links {
                link {
                  title
                  url
                }
              }
              partner {
                website
                image {
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }

        modules: allWpModule(
          sort: { fields: menuOrder, order: ASC }
          limit: 24
        ) {
          nodes {
            id
            title
            uri
          }
        }
      }
    `)

  if (!fields || !fields.footer || !fields.footer.general) return null

  const footer = type === 'ecd' ? fields.footer.ecd : fields.footer.pluriform

  if (!footer) return null

  return (
    <StyledFooter>
      <div className="container pt-lg-5 pt-3 pb-4">
        <div className="row">
          {footer.menu1 && (
            <div className="col-lg-3 pb-lg-0 pb-4">
              <strong className="text-primary font-size-2xl font-family-secondary d-block mb-2">
                {footer.menu1.title}
              </strong>

              {footer.menu1.links &&
                footer.menu1.links.map((item) => (
                  <a
                    className="d-flex align-items-center text-light mb-1"
                    key={item?.link?.title}
                    href={item?.link?.url}
                  >
                    <ArrowRightIcon className="mr-2 icon-medium" />
                    {parse(item?.link?.title || '')}
                  </a>
                ))}
            </div>
          )}

          {footer.menu2 && (
            <div className="col-lg-5 pb-lg-0 pb-4">
              <strong className="text-primary font-size-2xl font-family-secondary d-block mb-2">
                {footer.menu2.title}
              </strong>

              {type === 'pfz' ? (
                // @ts-ignore
                footer.menu2.links &&
                // @ts-ignore
                footer.menu2.links.map((item) => (
                  <a
                    className="d-flex align-items-center text-light mb-1"
                    key={item?.link?.title}
                    href={item?.link?.url}
                  >
                    <ArrowRightIcon className="mr-2 icon-medium" />
                    {parse(item?.link?.title)}
                  </a>
                ))
              ) : (
                <ModuleItems className="d-flex flex-wrap flex-column overflow-hidden">
                  {modules.nodes.map((node) => (
                    <a
                      className="d-flex align-items-center text-light mb-1"
                      key={node.id}
                      href={node.uri}
                    >
                      <ArrowRightIcon className="mr-2 icon-medium" />
                      {parse(node.title || '')}
                    </a>
                  ))}
                </ModuleItems>
              )}
            </div>
          )}

          <div className="col-lg-4">
            <strong className="text-primary font-size-2xl font-family-secondary d-block mb-2">
              {fields.footer.general.title}
            </strong>

            <div>
              <div className="d-inline-flex align-items-start mb-2">
                <LocationIcon className="icon-24 mt-1 mr-2" />
                <LocationContent content={fields.footer.general.address} />
              </div>

              <div>
                <a
                  href={`tel:${fields.footer.general.phone}`}
                  className="d-inline-flex align-items-start mb-2 text-white"
                >
                  <PhoneIcon className="icon-24 mr-2" />
                  <ParseContent content={fields.footer.general.phone} />
                </a>
              </div>

              <div>
                <a
                  href={`mailto:${fields.footer.general.email}`}
                  className="d-inline-flex align-items-start mb-2 text-white"
                >
                  <MailIcon className="icon-24 mr-2" />
                  <ParseContent content={fields.footer.general.email} />
                </a>
              </div>

              <div>
                <a
                  href={fields.footer.general.linkedin}
                  className="d-inline-flex align-items-start mb-4 text-white"
                  target="_blank" rel="noreferrer"
                >
                  <LinkedInIcon className="icon-24 mr-2" />
                  <p>Volg ons op LinkedIn</p>
                </a>
              </div>
            </div>
          </div>
        </div>

        {fields.footer.bottom && (
          <div className="row mt-lg-4 mt-2">
            <div className="col-lg-8 pb-lg-0 pb-4 d-flex align-items-end hide-last-element">
              {fields.footer.bottom.links &&
                fields.footer.bottom.links.map((link) => {
                  if (!link?.link || !link?.link.url) return null

                  return (
                    <React.Fragment key={link.link.title}>
                      <Link className="text-light" to={link.link.url}>
                        {link.link.title}
                      </Link>

                      <HrSecondary className="mx-4" />
                    </React.Fragment>
                  )
                })}
            </div>

            <div className="col-lg-4">
              <a
                href={fields.footer.bottom.partner?.website}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  alt="Partner"
                  src={
                    fields.footer.bottom.partner?.image?.localFile?.publicURL
                  }
                />
              </a>
            </div>
          </div>
        )}
      </div>
    </StyledFooter>
  )
}

export default Footer
