import React from 'react'

import logo from 'img/logo.svg'
import Phone from 'img/phone.inline.svg'
import Button from 'components/elements/Button'

// Third Party
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

interface Props {
  fields: any
}

const ButtonContainer = styled.div`
  @media screen and (max-width: 400px) {
    & a {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 358px) {
    & a {
      font-size: 12px;
    }
  }
`

const Container = styled.div`
  @media (min-width: 1200px){
    max-width: 1320px;
  }
  margin-left: auto;
  margin-right: auto;
`

const StyledPhone = styled(Phone)`
  @media screen and (max-width: 575px) {
    display: none;
  }
`

const MenuStripe = styled.div`
  outline: none;
  position: relative;
  right: 15px;
  height: 6px;
  width: 40px;
  margin: 6px 0;
  background-color: ${({ theme }) => theme.colors.dark};
  border-radius: 4px;
`

const MobileHomeMenuContainer = styled(motion.div)`
  position: absolute;
  overflow: hidden;
  width: 100vw;
  top: 85px;
  right: 15px;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.light};
`

const MobileHomeMenu = styled.div`
  padding: 20px;
`

const Logo = styled.div``

function HeaderHome({ fields }: Props) {
  const [mobileOpen, setMobileOpen] = React.useState<boolean>(false)


  return (
    <header className="py-lg-3 py-1">
      <Container className="container">
        <div className="row">
          <div className="col-6 col-lg-5 d-flex align-items-end">
            <Logo className="mr-lg-5">
              <img src={logo} alt="" />
            </Logo>

            <span className="font-family-tertiary d-lg-block d-none text-uppercase text-tertiary font-size-xl line-height-none">
              {fields.slogan}
            </span>
          </div>

          <ButtonContainer className="col-6 col-lg-7 d-flex align-items-lg-end align-items-center justify-content-end">
            <div className="d-lg-none d-flex">
              <div
                style={{ outline: 'none' }}
                role="button"
                tabIndex={-1}
                onKeyDown={() => setMobileOpen(!mobileOpen)}
                onClick={() => setMobileOpen(!mobileOpen)}
                className="pt-2"
              >
                <MenuStripe />
                <MenuStripe />
                <MenuStripe />
              </div>
              <AnimatePresence>
                {mobileOpen && (
                  <MobileHomeMenuContainer
                    initial={{ height: '0%' }}
                    animate={{ height: 'fit-content' }}
                    exit={{ height: '0%' }}
                    className="row justify-content-center"
                  >
                    <MobileHomeMenu className="col-8 flex-column d-flex">
                      <Button
                        isAnchor
                        to={fields.ecdButton.url}
                        className="tertiary mb-lg-0 mb-3 mr-sm-4 mr-0 font-size-base"
                        arrow
                      >
                        {fields.ecdButton.title}
                      </Button>

                      <Button
                        isAnchor
                        to={fields.clientButton.url}
                        className="tertiary mb-lg-0 mb-3 mr-sm-4 mr-0 font-size-base"
                        arrow
                      >
                        {fields.clientButton.title}
                      </Button>

                      <Button
                        isAnchor
                        to={fields.vacanciesButton.url}
                        className="tertiary mb-lg-0 mb-3 mr-sm-4 mr-0 font-size-base"
                        arrow
                      >
                        {fields.vacanciesButton.title}
                      </Button>

                      <Button
                        isAnchor
                        to={fields.phoneButton.url}
                        className="tertiary font-size-base"
                      >
                        <StyledPhone
                          className="mr-2"
                          style={{
                            width: 12,
                            height: 21,
                            marginTop: -5.5,
                            marginBottom: -5.5,
                          }}
                        />
                        <span className="ml-1">{fields.phoneButton.title}</span>
                      </Button>

                    </MobileHomeMenu>
                  </MobileHomeMenuContainer>
                )}
              </AnimatePresence>
            </div>

            <div className="d-lg-flex d-none">
              <Button
                isAnchor
                to={fields.ecdButton.url}
                className="tertiary mr-sm-4 mr-1 font-size-base"
                arrow
              >
                {fields.ecdButton.title}
              </Button>

              <Button
                isAnchor
                to={fields.clientButton.url}
                className="tertiary mr-sm-4 mr-1 font-size-base"
                arrow
              >
                {fields.clientButton.title}
              </Button>

              <Button
                isAnchor
                to={fields.phoneButton.url}
                className="tertiary font-size-base"
              >
                <StyledPhone
                  className="mr-2"
                  style={{
                    width: 12,
                    height: 21,
                    marginTop: -5.5,
                    marginBottom: -5.5,
                  }}
                />
                <span className="ml-1">{fields.phoneButton.title}</span>
              </Button>

              <Button
                isAnchor
                to={fields.vacanciesButton.url}
                className="tertiary mb-lg-0 mb-3 ml-sm-4 mr-0 font-size-base"
                arrow
              >
                {fields.vacanciesButton.title}
              </Button>

            </div>
          </ButtonContainer>
        </div>
      </Container>
    </header>
  )
}

export default HeaderHome
