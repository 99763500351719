import React from 'react'
import styled from 'styled-components'

import Theme from 'styles/Global'

import Footer from 'components/layout/Footer'
import Header from 'components/layout/Header'

import 'styles/all.css'

const Body = styled.div`
  background: #e9f9ff;
  // margin fix
  padding-bottom: 1px;
`

export interface LayoutContextProps {
  locale: string
  translations: Array<{
    href: string
    locale: string
  }>
  setIsScrollable: Function
}

export const LayoutContext = React.createContext({
  locale: 'nl_NL',
  translations: [],
  setIsScrollable: (bool: any) => bool,
})

interface LayoutProps {
  children: any
  type?: 'home' | 'pfz' | 'ecd'
  hideFooter?: boolean
  id?: number
}

const Layout: React.FC<LayoutProps> = ({
  children,
  type = 'pfz',
  hideFooter = false,
  id,
}) => (
  <Theme type={type}>
    <Header id={id} type={type} />
    <Body>{children}</Body>
    {!hideFooter && <Footer type={type} />}
  </Theme>
)

export default Layout
